import axios from 'axios';

export const handleTokens = (data) => {
  const expirationTime = Date.now() + 3600000;
  localStorage.setItem('accessToken', data.tokens.access);
  localStorage.setItem('refreshToken', data.tokens.refresh);
  localStorage.setItem('token', data.tokens);
  localStorage.setItem("accessTokenExpiration", expirationTime);
};

export const login = async (loginData, navigate, setError) => {
  try {
    const response = await fetch('https://tn-website-backend-cdcraegkgfdpdcge.westus2-01.azurewebsites.net/api/auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    });

    if (response.ok) {
      const data = await response.json();
      handleTokens(data);
      // navigate('/');
      return true;
    } else {
      setError('Invalid credentials');
      return false;
    }
  } catch (err) {
    setError('Login failed. Please try again.');
    return false;
  }
};

const getAccessToken = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const expirationTime = localStorage.getItem("accessTokenExpiration");
  if (Date.now() > expirationTime) {
    return refreshAccessToken(refreshToken);
  }
  return Promise.resolve(accessToken);
};

export const refreshAccessToken = (refreshToken) => {
  return fetch("https://tn-website-backend-cdcraegkgfdpdcge.westus2-01.azurewebsites.net/api/auth/token/refresh/", {
    method: "POST",
    body: JSON.stringify({ 
      refresh: refreshToken,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.access) {
        localStorage.setItem('accessToken', data.access);
        const expirationTime = Date.now() + 3600000;
        localStorage.setItem("accessTokenExpiration", expirationTime);
        return data.access;
      } else {
        throw new Error("Token refresh failed");
      }
    });
};

export const apiGet = async (url) => {
  url = 'https://tn-website-backend-cdcraegkgfdpdcge.westus2-01.azurewebsites.net/' + url 
  try {
    const accessToken = await getAccessToken();
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        refresh: refreshToken,
      },
    });
    const data = await response.data;
    return data; // Return only the data
  } catch (error) {
    console.error(`Error fetching from ${url}:`, error);
    throw error; // Re-throw the error for further handling
  }
};
