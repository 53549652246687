import React, { useState, useContext } from 'react';
import { Modal, Button, ListGroup, Spinner } from 'react-bootstrap';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CartContext } from 'components/CartContext';
import { MdDelete } from "react-icons/md";
// import CheckoutForm from 'components/Checkout'
// const stripePromise = loadStripe('pk_live_51QBLMNJzK27uOsH62g8z0MY6SlXf9ZCYuvpBV1HoJYIZigAU4iYmLGCRdqqbdyz0MknPCVoROsZaruEpU9NC6jtk00uvL1HzfQ');
const stripePromise = loadStripe('pk_test_51QBLMNJzK27uOsH6CrAzF64rKHiFvw3lfWbpI6CSDdr696aZWSRHy3wYNz1zSmLs0diHFkfGI9K8KOzUdWzNLEI5007j2RvPsq');

const CheckoutForm = ({ totalPrice, cartItems, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!stripe || !elements) {
      setError('Stripe has not been initialized');
      setLoading(false);
      return;
    }

    try {
      // Fetch the client secret from your backend
      const response = await fetch(
        'https://tn-website-backend-cdcraegkgfdpdcge.westus2-01.azurewebsites.net/api/payment/create-payment/',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: Math.round(totalPrice * 100),
            currency: 'usd',
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create payment intent');
      }

      const { clientSecret } = await response.json();

      const cardElement = elements.getElement(CardElement);

      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              // You can add billing details here if needed
            },
          },
        }
      );

      if (confirmError) {
        throw new Error(confirmError.message);
      }

      if (paymentIntent.status === 'succeeded') {
        setSuccess(true);
        // Handle successful payment (clear cart, show confirmation, etc.) setError('');
      }

    } catch (err) {
      console.error('Payment error:', err);
      setError(err.message || 'An error occurred while processing the payment.');
    } finally {
      setLoading(false);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
      <form onSubmit={handleSubmit} className="p-4">
        <div className="mb-4">
          <h4>Payment Information</h4>
          <p className="text-muted">Total Amount: ${totalPrice.toFixed(2)}</p>
        </div>
  
        <div className="mb-4">
          <CardElement options={cardElementOptions} />
        </div>
  
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
  
        {success && (
          <div className="alert alert-success" role="alert">
            Payment successful! Thank you for your purchase.
          </div>
        )}
  
        <Button 
          type="submit" 
          variant="primary" 
          className="w-100" 
          disabled={!stripe || loading}
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Processing...
            </>
          ) : (
            `Pay $${totalPrice.toFixed(2)}`
          )}
        </Button>
      </form>
  );
};

const CheckoutModalContent = ({ cartItems, totalPrice, onClose,  setIsCheckout}) => {
  const handleBackToCart = () => {
    setIsCheckout(false);  // Switch back to cart view
  };

  return (
    <div>
    {/* <Modal show={true} onHide={onClose}> */}
      <Modal.Header closeButton>
        <Modal.Title>Checkout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Elements stripe={stripePromise}>
          <CheckoutForm totalPrice={totalPrice} cartItems={cartItems} onClose={onClose} />
        </Elements>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleBackToCart}>Back to Cart</Button>
      </Modal.Footer>
    {/* </Modal> */}
    </div>
  );
};

const ShoppingCartModalContent = ({ cartItems, totalPrice, onClose, setIsCheckout }) => {
  const { handleIncreaseQuantity, handleDecreaseQuantity, handleRemoveItem } = useContext(CartContext);
  const handleProceedToCheckout = () => {
    setIsCheckout(true);  // Switch to checkout view
  };

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Shopping Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {cartItems.length === 0 ? (
          <p>Your cart is empty</p>
        ) : (
          <ListGroup>
            {cartItems.map((item) => (
              <ListGroup.Item key={item.id}>
                {item.title} - ${item.cost.toFixed(2)} x {item.quantity}
                <div className="float-end">
                  <Button variant="outline-secondary" onClick={() => handleIncreaseQuantity(item.id)}>
                    +
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={() => handleDecreaseQuantity(item.id)}
                    className="mx-2"
                    disabled={item.quantity <= 1}
                  >
                    -
                  </Button>
                  <Button variant="outline-danger" onClick={() => handleRemoveItem(item.id)}>
                    <MdDelete size={25}/>
                  </Button>
                </div>
              </ListGroup.Item>
             
            ))}
          </ListGroup>
        )}
        <h4 className='mt-3'>Total: ${totalPrice.toFixed(2)}</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
          <Button onClick={handleProceedToCheckout} disabled={cartItems.length === 0} >Proceed to Checkout</Button>
      </Modal.Footer>
    </div>
  );
};

const ShoppingCart = ({ cartItems, onClose }) => {
  const [isCheckout, setIsCheckout] = useState(false);
  const totalPrice = cartItems.reduce((total, item) => total + item.cost * item.quantity, 0);

  return (
  <Modal show={true} onHide={onClose}>
        {!isCheckout ? (
          <div className="cart-view">
            <ShoppingCartModalContent
              cartItems={cartItems}
              totalPrice={totalPrice}
              onClose={onClose}
              setIsCheckout={setIsCheckout}
            />
          </div>
        ) : (
          <div className="checkout-view">
            <CheckoutModalContent 
              cartItems={cartItems}
              totalPrice={totalPrice}
              onClose={onClose}
              setIsCheckout={setIsCheckout}
            />
          </div>
        )}
      </Modal>
  )};

export default ShoppingCart;
