import React from 'react';
import heroVideo from 'assets/images/offers/video1.mp4'
import './Shop.css';
import Hero from 'components/Hero/Hero';
import ProductList from './components/ProductList';
import ProjectList from './components/ProjectList';
import OfferList from './components/OfferList';

const Shop = () => {
  return (
    <div>
      <Hero
        source={heroVideo}
        isVideo={true}
        title="Our Shop"
        subtitle="Explore our range of PLM consulting products and services tailored to your needs."
        buttonLink={'#offer_section'}
        buttonText="Learn More"
      />
      <ProductList />
      <ProjectList />
      <OfferList />
    </div>

  );
};

export default Shop;