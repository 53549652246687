import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import LoginPage from 'pages/Authentification/Login';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Shop from 'pages/Shop/Shop';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { CartProvider } from 'components/CartContext';

const App = () => {
  useEffect(() => {
    document.title = "True North PLM";
  }, []);

  const websiteContent = [
    { title: 'Home', link: '/' },
    { title: 'About Us', link: '/about' },
    { title: 'Our Services', link: '/offers' },
    { title: 'Our Shop', link: '/shop' },
    { title: 'Contact Us', link: '/contact' },
  ];

  return (
    <CartProvider>
      <Router>
        <div id="root">
          <Header content={websiteContent} />
          <div className="content">
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/shop" element={<PrivateRoute><Shop /></PrivateRoute>} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
};

export default App;