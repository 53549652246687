import React, { useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import CardItem from './CardItem';
import consultingImage from 'assets/images/offers/consulting.jpg';
import cadImage from 'assets/images/offers/cad.jpg';
import softwareImage from 'assets/images/offers/software.jpg';
import training from 'assets/images/offers/training.jpg';

const OfferList = () => {
  const [show, setShow] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (offer) => {
    setSelectedOffer(offer);
    setShow(true);
  };

  const offers = [
    {
      title: 'Consulting',
      description: 'Expert guidance to streamline your PLM processes.',
      details: 'Our consulting services include workflow optimization, strategy development, and training programs to ensure your team is equipped for success. Business Process Consulting to ensure the tools available are used properly and efficiently for a trustworthy Bill of Materials.',
      image: consultingImage,
    },
    {
      title: 'CAD Integration',
      description: 'Seamless CAD solutions for your design needs.',
      details: 'We provide advanced CAD integration to enhance design workflows, including tool customization and interoperability solutions. CAD experts available to support with Reverse Engineering, CAD Modeling, Component Family Creation and Management, and more.',
      image: cadImage,
    },
    {
      title: 'App Development & Deployment',
      description: 'Custom tools tailored to your unique requirements, seemlessly integrated.',
      details: 'From concept to deployment, we develop PLM tools that align perfectly with your processes and goals to maximize your efficiency and minimize downtime. Plug and Play Applications will ensure your business has all the tools you need to be successful, without heavy customizations.',
      image: softwareImage,
    },
    {
      title: 'Training',
      description: 'Online training modules filled with micro-learnings to ensure all users have the resources they need to succeed.',
      details: "Unlock your team's potential with our cutting-edge online training service. Designed for professionals, our courses combine expert-led instruction with interactive content to deliver a dynamic and effective learning experience. Whether you're upskilling your workforce or onboarding new talent, our platform offers flexible, on-demand modules tailored to your industry needs.",
      image: training,
    },
  ];

  return (
    <section id="offer_section">
      <Container>
        <h2 className="text-center mb-4">Our Services</h2>
        <Row className="d-flex align-items-stretch">
          {offers.map((offer, index) => (
            <Col md={6} lg={6} className="mb-4" key={index} data-aos="fade-up">
              <CardItem
                image={offer.image}
                title={offer.title}
                description={offer.description}
                buttonText="Learn More"
                onButtonClick={() => handleShow(offer)}
              />
            </Col>
          ))}
        </Row>
      </Container>

      {selectedOffer && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedOffer.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{selectedOffer.details}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary">Get in touch</Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
};

export default OfferList;
