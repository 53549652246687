import React from "react";
import './Hero.css';
import { Link } from "react-router-dom";
import logo from 'assets/images/shared/logo&name.svg'

const Hero = ({ source, isVideo = false, title, subtitle, isButton = true, buttonLink, buttonText }) => {
  return (
    <div className="hero-section">
      {isVideo ? (
        <video
          className="hero-video"
          src={source}
          autoPlay
          loop
          muted
        ></video>
      ) : (
        <img
          className="hero-image"
          src={source}
          alt="Hero Background"
        />
      )}
      <div className="overlay"></div>
      <div className="hero-content">
        <img
          src={logo}
          alt="True North PLM Logo"
          className="hero-logo"
        />
        <h1 className="hero-title">{title}</h1>
        <p className="hero-subtitle">{subtitle}</p>
        {isButton && (
          buttonLink.startsWith("#") ? (
            <a className="btn btn-light learn-more-btn" href={buttonLink}>{buttonText}</a>
          ) : (
            <Link className="btn btn-light learn-more-btn" to={buttonLink}>{buttonText}</Link>
          )
        )}
      </div>
    </div>
  );
};

export default Hero;
