import React, { useState, useContext } from 'react';
import { Navbar, Nav, Container, Button, Offcanvas, Form, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from "react-router-dom";
import { FaSearch, FaShoppingCart } from 'react-icons/fa'; //FaUser
import logo from 'assets/images/shared/logo.svg'
import './Header.css'
import ShoppingCart from 'components/ShoppingCart';
import { CartContext } from 'components/CartContext';

const Header = ({ content }) => {
  const { cart } = useContext(CartContext);
  const [showSearch, setShowSearch] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  const handleShowSearch = () => setShowSearch(true);
  const handleCloseSearch = () => setShowSearch(false);

  // Filter content based on the search query
  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setQuery(searchTerm);

    if (searchTerm.length > 0) {
      const filteredResults = content.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  };

  const [showCart, setShowCart] = useState(false);
  const isAuthenticated = localStorage.getItem('token') !== null;

  const handleShowCart = () => {
    if (isAuthenticated) {
        setShowCart(true);  // Show the modal if logged in
    } else {
        alert('Please log in to view your cart.');  // Alert if not logged in
    }
  };
  const handleCloseCart = () => setShowCart(false);

  const handleLogin = () => {
    navigate('/login'); // Redirect to login page
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
        <Container className='p-0'>
            <Navbar.Brand href="/">
                <img
                    src={logo}
                    alt="True North PLM Logo"
                    style={{ height: '70px', margin: '10px' }}
                />
                True North PLM
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="w-100">
                <Nav className="mx-auto">
                    <LinkContainer to="/">
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/about">
                        <Nav.Link>About Us</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/shop">
                        <Nav.Link>Shop</Nav.Link>
                    </LinkContainer>
                    <a href="https://training.truenorthplm.com/" target="_blank" rel="noopener noreferrer" className="nav-link">
                        Training
                    </a>
                    <LinkContainer to="/contact">
                        <Nav.Link>Contact</Nav.Link>
                    </LinkContainer>
                </Nav>
                <div className="d-flex align-items-center">
                    <Button
                        variant="link"
                        onClick={handleShowSearch}
                        className="custom-button mx-2 px-0"
                    >
                        <FaSearch size={15} color="white" className="icon" />
                    </Button>
                    <Nav.Link href="#" className="custom-button mx-2" onClick={handleShowCart}>
                        <FaShoppingCart size={15} color="white" className="icon" />
                        {cart.length > 0 && (
                            <span className="badge badge-light">
                                {cart.reduce((total, item) => total + item.quantity, 0)}
                            </span>
                        )}
                    </Nav.Link>
                    {/* <LinkContainer to="/profile" className="custom-button mx-2">
                        <Nav.Link>
                            <FaUser size={15} color="white" className="icon" />
                        </Nav.Link>
                    </LinkContainer> */}
                    {isAuthenticated ? (
                        <Button
                            variant="outline-light"
                            onClick={handleLogout}
                            className="mx-2"
                        >
                            Logout
                        </Button>
                        ) : (
                        <Button
                            variant="outline-light"
                            onClick={handleLogin}
                            className="mx-2"
                        >
                            Login
                        </Button>
                    )}
                </div>
            </Navbar.Collapse>
        </Container>
        <Offcanvas
            show={showSearch}
            onHide={handleCloseSearch}
            placement="top"
            style={{ height: '200px' }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Search</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {/* Search Bar */}
                <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={query}
                    onChange={handleSearch}
                    autoFocus
                />

                {/* Display Search Results */}
                {results.length > 0 && (
                    <ListGroup variant="flush" className="mt-3">
                    {results.map((result, index) => (
                        <ListGroup.Item key={index}>
                        <Link to={result.link}>{result.title}</Link>
                        </ListGroup.Item>
                    ))}
                    </ListGroup>
                )}
            </Offcanvas.Body>
      </Offcanvas>
      {showCart && <ShoppingCart cartItems={cart} onClose={handleCloseCart} />}
    </Navbar>
  );
};

export default Header;
