import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('token') !== null;
  const location = useLocation();

  return isAuthenticated ? (
    <div className="app-container">
      <main className="main-content">{children}</main>
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} /> 
  );
};

export default PrivateRoute; 