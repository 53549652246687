import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ totalPages, currentPage, onPageChange }) => (
  <Pagination className="justify-content-center">
    {[...Array(totalPages)].map((_, idx) => (
      <Pagination.Item
        key={idx + 1}
        active={idx + 1 === currentPage}
        onClick={() => onPageChange(idx + 1)}
      >
        {idx + 1}
      </Pagination.Item>
    ))}
  </Pagination>
);

export default PaginationComponent;
