import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import CardItem from './CardItem';
import PaginationComponent from './PaginationComponent';
import { apiGet } from 'utils/api';
import { CartContext } from 'components/CartContext';

const ProductList = () => {
  const { handleAddToCart } = useContext(CartContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productUrl = 'api/products/list/';
        const productData = await apiGet(productUrl);
        setProducts(productData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const currentItems = products.slice(indexOfLastItem - itemsPerPage, indexOfLastItem);

  return (
    <Container>
      <h2 className="text-center mb-4">Our Products</h2>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Row className="d-flex align-items-stretch">
            {currentItems.map((product) => (
              <Col md={6} lg={6} className="mb-4" key={product.id} data-aos="fade-up">
                <CardItem
                  image="https://images.pexels.com/photos/13003485/pexels-photo-13003485.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" //{product.imageurl}
                  title={product.title}
                  description={product.description}
                  cost={product.cost}
                  buttonText="Add to Cart"
                  onButtonClick={() => handleAddToCart(product)}
                />
              </Col>
            ))}
          </Row>
          <PaginationComponent
            totalPages={Math.ceil(products.length / itemsPerPage)}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </>
      )}
    </Container>
  );
};

export default ProductList;
