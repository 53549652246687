import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { useInView } from "react-intersection-observer";
import cfd1 from 'assets/images/offers/cfd1.png'
import cfd2 from 'assets/images/offers/cfd2.png'
import automatedBom from 'assets/images/offers/automatedBom.png'

const Project = ({ title, description, image, alt }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <div
      className={`project-item ${inView ? "in-view" : ""} py-3`}
      ref={ref}
    >
      <img src={image} alt={alt} className="project-image" />
      <div className="project-description">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const projects = [
  {
    title: "CFD / NVH / FEA Pipeline with CAD",
    description:
      "Utilizing advanced CAD modeling, this project showcases how our pipeline streamlines design validation and integrates with simulation tools. By automating complex simulations, we reduce manual effort and increase precision.",
    image: cfd1,
    alt: "CFD pipeline using CAD",
  },
  {
    title: "Automation for Efficiency",
    description:
      "This project demonstrates how automation accelerates testing cycles and enhances efficiency. By leveraging modern tools, we achieved a significant reduction in time-to-market.",
    image: cfd2,
    alt: "Automation for efficiency",
  },
  {
    title: "Automated BOM Extract",
    description:
      "Streamline your workflow with our automated BOM loading solution, which integrates seamlessly with SharePoint and shared file locations to eliminate the need for unreliable Excel reports. This system automates the configuration and loading of Bills of Materials (BOMs), ensuring they are always up-to-date—whether refreshed nightly or on-demand by the user. By accelerating processes and enhancing data accuracy, it guarantees that your BOMs are ready for use whenever you need them.",
    image: automatedBom,
    alt: "Automated BOM Integration",
  },
];

const ProjectExamples = () => {
  return (
    <section className="project-examples">
      <div className="container">
        <h2 className="section-title">Project Examples</h2>
        <Carousel>
          {projects.map((project, index) => (
            <Carousel.Item key={index}>
              <Project
                title={project.title}
                description={project.description}
                image={project.image}
                alt={project.alt}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default ProjectExamples;
