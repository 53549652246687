import React from 'react';
import { Card, Button } from 'react-bootstrap';

const CardItem = ({ image, title, description, cost, buttonText, onButtonClick }) => (
  <Card className="offer-card shadow-sm h-100">
    <Card.Img variant="top" src={image} className="card-image" />
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Card.Text>{description}</Card.Text>
      {cost &&(
        <h4 className='my-4'>{cost}$</h4>
      )}
      {buttonText && (
        <Button variant="primary" onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </Card.Body>
  </Card>
);

export default CardItem;
