import React, { useState } from "react";
import { Link } from "react-router-dom";
import heroVideo from 'assets/images/home/video2.mp4';
import iceberg from 'assets/images/home/iceberg.png';
import pipeline from 'assets/images/home/pipeline.png'
import team from 'assets/images/home/team.jpg';
import consulting from 'assets/images/home/consulting.jpg';
import development from 'assets/images/home/development.jpg'
import cad from 'assets/images/home/cad.jpg';
import training from 'assets/images/home/training.jpg';
import { FaCheckCircle, FaRegLightbulb, FaChartLine } from 'react-icons/fa';
import './Home.css';
import Hero from 'components/Hero/Hero';
import { linkedinPosts } from 'data/LinkedInPosts';
import LinkedInCarousel from "components/LinkedInCarousel/LinkedInCarousel";

const AboutUs = () => {
  return (
    <section className="about-us bg-light py-5">
      <div className="container">
        <h2 className="text-center mb-4">Who Are We?</h2>
        <div className="row align-items-center">
          <div className="col-md-6">
            <img
              src={team}
              alt="Team"
              className="img-fluid rounded about-us-img"
            />
          </div>
          <div className="col-md-6">
            <p>
              We are a powerhouse of expertise, driven by a diverse team of innovators who specialize in maximizing your systems’ potential. 
            </p>
            <p>
              With over <strong>60 years of combined CAD expertise and 30 years of cumulative PLM mastery</strong>, we don’t just understand industry challenges – we’ve faced them.
            </p>
            <p>
              We specialize in <strong>helping businesses optimize their product development and management processes</strong>. 
              Our team of experts ensures your products are delivered efficiently, from concept to production, using the best PLM
              practices and tools.
            </p>
            <p> 
              Partner with us to transform your processes, eliminate inefficiencies, and unlock unparalleled value for your business. 
              With us, your success isn’t just a goal – it’s a guarantee.
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Link className="btn btn-primary learn-more-btn" to="/about">More about us</Link>
        </div>
      </div>
    </section>
  );
};

const PainPoint = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <section className="pain-point py-5">
      <div className="container">
        <h2 className="text-center mb-4">What We Can Do: Reduce PLM Pain Points</h2>
        <div className="row align-items-center">
          <div className="col-md-6">
            <p> 
              The PLM and design processes are essential for driving innovation and managing product lifecycles, 
              but they often come with hidden costs that can quickly escalate—such as maintenance, upgrade incompatibilities, 
              and new system integrations. These unforeseen expenses can strain resources and delay projects. 
              With our product, these challenges can be anticipated, controlled, and significantly reduced, 
              empowering businesses to optimize processes, improve efficiency, 
              and stay within budget without compromising on quality or innovation.
            </p>
            <p>
              <strong>True North PLM Plug-and-Play applications</strong> help you to avoid those unexpected expenses.
            </p>
          </div>
          <div className="col-md-6">
            <div className="iceberg-container d-flex">
              {/* Iceberg Image */}
              <div
                className="iceberg-image"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={iceberg}
                  alt="Iceberg"
                  className="img-fluid"
                />
              </div>

              {/* Animated List */}
              <div className={`animated-list ${isHovered ? "active" : ""}`}>
                <ul>
                  <li>Requirements Gathering</li>
                  <li>Development</li>
                  <li>UAT</li>
                  <li>Knowledge Transfer</li>
                  <li>Maintenance</li>
                  <li>Customization Updates</li>
                  <li>Upgrade Incompatibilities</li>
                  <li>Continuous Training</li>
                  <li>Business Adoption</li>
                  <li>Growing Pains</li>
                  <li>New Process Integrations</li>
                  <li>New System Integrations</li>
                </ul>
              </div>
            </div>
          </div>
        </div>       
      </div>
    </section>
  );
};

const AutomationSection = () => {
  return (
    <section className="added-value bg-light py-5">
      <div className="container">
        <div className="automation-section">
          <h2 className="text-center mb-4">How We Do It: Automation Tools & Pipeline</h2>

          <div className="image-container">
            <img
              src={pipeline}
              alt="Automation Pipeline"
              className="automation-image"
            />
          </div>
          <div className="text-container">
            <p>
              Our automation pipeline streamlines processes, ensuring quicker
              deliveries while reducing operational costs. By leveraging modern
              technology and efficient workflows, we maximize performance and minimize
              resource usage.
            </p>
          </div>
            <div className="d-flex justify-content-center mt-4">
              <Link className="btn btn-primary learn-more-btn" to="/offers">Learn More</Link>
            </div>
        </div>
      </div>
    </section>
  );
};

const AddedValue = () => {
  return (
    <section className="added-value py-5">
      <div className="container">
        <h2 className="text-center mb-4">Why Choose Us?</h2>
        <div className="row text-center">
          <div className="col-md-4">
            <div className="added-value-item">
              <FaCheckCircle size={50} color="#00aaff" />
              <h3>Expertise</h3>
              <p>We bring years of deep expertise in PLM, having worked with top manufacturers worldwide.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="added-value-item">
              <FaRegLightbulb size={50} color="#00aaff" />
              <h3>Tailored Solutions</h3>
              <p>Our solutions are customized to meet your unique challenges and goals.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="added-value-item">
              <FaChartLine size={50} color="#00aaff" />
              <h3>Results-Driven</h3>
              <p>We focus on delivering measurable results that optimize product lifecycle efficiency.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const OurServices = () => {
  return (
    <section className="services bg-light py-5">
      <div className="container">
        <h2 className="text-center mb-4">Our Services</h2>
        <div className="row">
          <div className="col-md-3">
            <div className="service-card">
              <img src={consulting} alt="Consulting" className="img-fluid" />
              <h3>Consulting</h3>
              <p>We provide expert consulting to help you navigate the complexities of PLM systems.</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="service-card">
              <img src={development} alt="Implementation" className="img-fluid" />
              <h3>App Development</h3>
              <p>We assist in the seamless implementation of PLM systems tailored to your business needs.</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="service-card">
              <img src={cad} alt="Implementation" className="img-fluid" />
              <h3>CAD Support</h3>
              <p>We bring support with CAD and 3D modeling to your team.</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="service-card">
              <img src={training} alt="Training" className="img-fluid" />
              <h3>Training</h3>
              <p>Our training programs ensure your team is proficient in using PLM tools effectively.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <Link className="btn btn-primary learn-more-btn" to="/offers">See More</Link>
      </div>
    </section>
  );
};

const CallToAction = () => {
  return (
    <section className="call-to-action py-5 text-center" style={{ backgroundImage: `url('./assets/images/cta-background.jpg')` }}>
      {/* <div className="overlay"></div> */}
      <h2>Ready to transform your product lifecycle?</h2>
      <p className="text-center">Contact us today and discover how we can help you optimize your product development processes.</p>
      <Link to="/contact" className="btn btn-light cta-btn">Get in Touch</Link>
    </section>
  );
};

const Home = () => {
    return (
      <div>
        <Hero
          source={heroVideo}
          isVideo={true}
          title="Transforming Product Lifecycles"
          subtitle="Your trusted partner in managing and optimizing product development processes"
          buttonLink={'/offers'}
          buttonText="Learn More"
        />
        <AboutUs />
        <PainPoint />
        <AutomationSection />
        <AddedValue />
        <OurServices />
        <LinkedInCarousel postUrls={linkedinPosts} />
        <CallToAction />
      </div>
    );
  };

export default Home;
